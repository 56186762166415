<template>
  <div id="general-info">
    <div id="links">
      <router-link class="nav-link" to="gifts">
        Virtual Gifts
      </router-link>
      <router-link v-if="roomProfile.motd" class="nav-link" to="rules">
        Room Rules
      </router-link>
    </div>
    <router-view />
  </div>
</template>
<script>

import { mapGetters } from 'vuex';

export default {
  name: "General",
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters([
      'roomProfile'
    ]),
  },
  created() {
    setTimeout(() => {
      if (window.innerWidth <= 740) {
        this.$root.$emit('scrollTo', '#general-info');
      }
    }, 300);
  },
}
</script>

<style scoped>
#general-info {
  min-height: 550px;
}
#links {
  margin: 35px 40px 0;
  border-bottom: 1px solid #dbdbdb;
  text-align: left;
}
a.nav-link {
  position: relative;
  display: inline-block;
  margin-right: 20px;
  padding-bottom: 2px;
  font-weight: 400;
  font-size: 16px;
  color: #808080;
  line-height: 42px;
  cursor: pointer;
  transition: .2s;
}
a.nav-link:after {
  content: '';
  position: absolute;
  display: block;
  height: 3px;
  width: 100%;
  bottom: -1px;
  left: 0;
  background-color: transparent;
  transition: .2s;
}
a.nav-link.router-link-active,
a.nav-link:hover {
  color: #3d95cc;
}
a.nav-link:active:after,
a.nav-link.router-link-active:after {
  background-color: #3d95cc;
}
</style>
